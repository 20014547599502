import React, { useState, useEffect } from 'react'
import { Typography, Grid, withStyles, Button, MenuItem, TextField, LinearProgress, createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import ThumbUpIcon from "@material-ui/icons/ThumbUpOutlined";
import { fetchGetWT, fetchPostWT, fetchDeleteWT } from '../../../helpers/HttpHelper';
import { apiGetParamsReplace, endpoints } from '../../../constants/services.constants';
import { Link as RouterLink } from 'react-router-dom';
import { getNumberOfWeekYear } from '../../../helpers/UtilHelper';
// import { getDate } from 'date-fns';
import { lightGreen } from '@material-ui/core/colors';
import { RepublicaMuiTheme } from '../../../RepublicaMuiTheme';
import { Helmet } from 'react-helmet';
const Link1 = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />);

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    rootButtons: {
        flexGrow: 1,
        // marginTop: 10,
        marginBottom: 20,
        maxWidth: 200
    },
    buttonLeft: { marginRight: 5 },
    floatingWeekSelector: {
        position: 'fixed',
        top: 100,
        right: 50
    }
});

function CorrectorWeek(props) {
    const { classes } = props;
    const [yearWeekLoading, setYearWeekLoading] = useState(true);
    // const yearWeekInit = initWeekYear(); 
    const [yearWeek, setYearWeek] = useState(initWeekYear());
    const [yearWeekSegmentsLoading, setYearWeekSegmentsLoading] = useState(true);
    const [yearWeekSegments, setYearWeekSegments] = useState(null);
    useEffect(() => {

        // console.log(yearWeek)
        fetchWeekSegments();

    }, [yearWeek]);

    useEffect(() => {
        // console.log(weekSegments)
        // if(weekSegments!=null)console.log(weekSegments.find(x=>x.customer==18))
        setYearWeekLoading(false);
    }, [yearWeekSegments]);


    function initWeekYear() {
        var prevWeek = new Date();
        prevWeek.setDate(prevWeek.getDate() - 7);

        var prevWeekYear = getNumberOfWeekYear(prevWeek);
        var prevWeekNumber = prevWeekYear[0];
        var prevWeekYearNumber = prevWeekYear[1].toString().substr(-2);

        //  console.log(prevWeekNumber)
        //  console.log(prevWeekYearNumber )
        
        if (parseInt(prevWeekNumber) === 54) {
            prevWeekNumber = 1;
            prevWeekYearNumber = (parseInt(prevWeekYearNumber) + 1).toString()
        }
        return props.initYear != null && props.initWeek != null ? props.initWeek + "|" + props.initYear : prevWeekNumber + "|" + prevWeekYearNumber;

    }

    const handleWeekChange = event => {
        if (event.target.value !== yearWeek) {
            setYearWeekSegments(null);
            setYearWeekLoading(true);
            setYearWeek(event.target.value);
        }
    };

    function getYearWerkVal() {
        return yearWeek.split("|");
    }
    function fetchWeekSegments() {
        const w = getYearWerkVal();
        // console.log(w);
        const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTS_GET, w[1], w[0]);
        setYearWeekSegmentsLoading(true);
        fetchGetWT(
            endpoint,
            data => {
                setYearWeekSegmentsLoading(false);
                setYearWeekSegments(data);
            },
            error => {
                setYearWeekSegmentsLoading(false);
                console.log(new Error(error.message))
                setYearWeekLoading(JSON.stringify(new Error(error.message)));
            }
        );
    }


    function RenderCustomerGrid(customer) {
        const w = getYearWerkVal();
        return <Grid item xs={4}>
            <RenderCustomer classes={classes}
                weekSegments={yearWeekSegments}
                weekSegmentsLoading={yearWeekSegmentsLoading}
                fetchWeekSegments={fetchWeekSegments}
                // handleWeekChange={handleWeekChange}
                customer={customer}
                week={w[0]}
                year={w[1]}

            />
        </Grid>
    }
    return (
        <div>

            <RenderWeekSelector classes={classes} handleWeekChange={handleWeekChange} week={yearWeek} />
            <Typography variant="h3" align="center">Uge {getYearWerkVal()[0]} - 20{getYearWerkVal()[1]}</Typography>
            <br />
            <br />

            {/* {loading ? <LinearProgress /> : undefined} */}
            {yearWeekLoading === true ? <LinearProgress /> :
                <div className={classes.root}>
                    <Grid container spacing={2} className={classes.root} align="center">
                        {RenderCustomerGrid({ id: 17, name: "DagliBrugsen" })}
                        {RenderCustomerGrid({ id: 11, name: "SuperBrugsen" })}
                        {RenderCustomerGrid({ id: 20, name: "Kvickly" })}
                        {RenderCustomerGrid({ id: 10, name: "Fakta" })}
                        {RenderCustomerGrid({ id: 51, name: "Coop 365" })}
                    </Grid>

                    {yearWeekLoading === false ? undefined : <Typography variant="caption" align="center ">Error loading saved sections: {yearWeekLoading}</Typography>}
                </div>
            }
        </div>
    )
}

function RenderWeekSelector(props) {
    const { classes } = props;
    var today = new Date();
    var todayWeekYear = getNumberOfWeekYear(today);
    var todayWeek = todayWeekYear[0];
    var todayYear = todayWeekYear[1].toString().substr(-2);

    var todayMinus3 = new Date();
    todayMinus3.setDate(todayMinus3.getDate() - 1 * 21);
    var todayWeekYearMinus3 = getNumberOfWeekYear(todayMinus3);
    var todayWeekMinus3 = todayWeekYearMinus3[0];
    var todayYearMinus3 = todayWeekYearMinus3[1].toString().substr(-2);

    var todayMinus2 = new Date();
    todayMinus2.setDate(todayMinus2.getDate() - 1 * 14);
    var todayWeekYearMinus2 = getNumberOfWeekYear(todayMinus2);
    var todayWeekMinus2 = todayWeekYearMinus2[0];
    var todayYearMinus2 = todayWeekYearMinus2[1].toString().substr(-2);

    var todayMinus1 = new Date();
    todayMinus1.setDate(todayMinus1.getDate() - 1 * 7);
    var todayWeekYearMinus1 = getNumberOfWeekYear(todayMinus1);
    var todayWeekMinus1 = todayWeekYearMinus1[0];
    var todayYearMinus1 = todayWeekYearMinus1[1].toString().substr(-2);

    var todayPlus1 = new Date();
    todayPlus1.setDate(todayPlus1.getDate() + 1 * 7);
    var todayWeekYearPlus1 = getNumberOfWeekYear(todayPlus1);
    var todayWeekPlus1 = todayWeekYearPlus1[0];
    var todayYearPlus1 = todayWeekYearPlus1[1].toString().substr(-2);

    var todayPlus2 = new Date();
    todayPlus2.setDate(todayPlus2.getDate() + 2 * 7);
    var todayWeekYearPlus2 = getNumberOfWeekYear(todayPlus2);
    var todayWeekPlus2 = todayWeekYearPlus2[0];
    var todayYearPlus2 = todayWeekYearPlus2[1].toString().substr(-2);

    var todayPlus3 = new Date();
    todayPlus3.setDate(todayPlus3.getDate() + 3 * 7);
    var todayWeekYearPlus3 = getNumberOfWeekYear(todayPlus3);
    var todayWeekPlus3 = todayWeekYearPlus3[0];
    var todayYearPlus3 = todayWeekYearPlus3[1].toString().substr(-2);

    var todayPlus4 = new Date();
    todayPlus4.setDate(todayPlus4.getDate() + 4 * 7);
    var todayWeekYearPlus4 = getNumberOfWeekYear(todayPlus4);
    var todayWeekPlus4 = todayWeekYearPlus4[0];
    var todayYearPlus4 = todayWeekYearPlus4[1].toString().substr(-2);

    var dts = [
        [todayWeekMinus3, todayYearMinus3],
        [todayWeekMinus2, todayYearMinus2],
        [todayWeekMinus1, todayYearMinus1],
        [todayWeek, todayYear],
        [todayWeekPlus1, todayYearPlus1],
        [todayWeekPlus2, todayYearPlus2],
        [todayWeekPlus3, todayYearPlus3],
        [todayWeekPlus4, todayYearPlus4]
    ]
    return (
        <TextField
            select
            variant="outlined"
            className={classes.floatingWeekSelector}
            value={props.week}
            onChange={props.handleWeekChange}
        // inputProps={{ name: "age", id: "outlined-age-simple" }}
        >
            {dts.map((x, i) => {
                return <MenuItem key={i} value={x[0] + "|" + x[1]}>{x[0]} - 20{x[1]}</MenuItem>
            })}

        </TextField>
    )
}

function RenderCustomer(props) {
    const { classes } = props;

    const segmentKval = "Kval";
    const segmentMilj = "Milj";
    const segmentBarn = "Barn";
    const segmentRest = "Rest";

    function hasSegment(segment) {  

        return props.weekSegments != null &&
            props.weekSegments.find(
                x => x.customer == props.customer.id
                    && x.segment == segment
            ) !== undefined;
    }

    function renderSegment(segment) {

        const hasSegment2 = hasSegment(segment);

        function postSegment() {
            const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTS_POST);
            fetchPostWT(
                endpoint,
                JSON.stringify({
                    "week": parseInt(props.week),
                    "year": parseInt(props.year),
                    "customer": props.customer.id,
                    "segment": segment
                })
                ,
                data => {
                    // console.log(data);
                    props.fetchWeekSegments();
                    // setWeekSegments(data);
                },
                error => {
                    console.log(new Error(error.message))
                    // setWeekLoading(false);
                }
            );
        }
        function deleteSegment() {
            const endpoint = apiGetParamsReplace(endpoints.PHOTOCOMBINATION_WEEKSEGMENTS_DELETE,
                props.customer.id, props.year, props.week, segment);
            fetchDeleteWT(
                endpoint, null,
                data => {
                    // console.log(data);
                    props.fetchWeekSegments();
                    // setWeekSegments(data);
                },
                error => {
                    console.log(new Error(error.message))
                    // setWeekLoading(false);
                }
            );
        }
        const theme = createMuiTheme(JSON.parse(JSON.stringify(RepublicaMuiTheme)));
        theme.palette.secondary.main = lightGreen[800];
        theme.palette.secondary.dark = lightGreen[900];
        

        return (
            <Grid container className={classes.rootButtons} >
                <Grid item xs className={classes.buttonLeft}>
                    <Button component={Link1}
                        to={"/corrector/segment/" + props.customer.id + "/" + props.year + "/" + props.week + "/" + segment}
                        variant="contained" color="primary" fullWidth>
                        {segment === "Milj" ? "Miljø" : segment}
                    </Button>
                </Grid>
                <MuiThemeProvider theme={theme}>
                    <Grid item xs={2} component={Button} variant="contained" disabled={props.weekSegmentsLoading}
                        color={hasSegment2 ? "secondary" : "primary"}
                        onClick={hasSegment2 ? deleteSegment : postSegment}
                    >
                        <ThumbUpIcon className={classes.rightIcon} />
                    </Grid>
                </MuiThemeProvider>
            </Grid>
        );
    }

    return (
        <>
            <Helmet>
                <title>Billedkombinationer</title>
            </Helmet>
            <Typography variant="h4" >{props.customer.name}</Typography>
            <br />
            {renderSegment(segmentRest)}
        </>
    )
}


export default withStyles(styles)(CorrectorWeek);